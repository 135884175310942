.home {
  text-align: center;
}
.home.large {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home > .page-container > .image-container {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

.home > .page-container > .image-container .imgLink {
  display: flex;
  flex-direction: column;
  font-weight: bold;
  padding-bottom: 0.5rem;
}

.home > .page-container > .image-container .img-text {
  padding-top: 0.5rem;
}

.home > .page-container > .image-container .imgLink > img {
  max-height: 11rem;
}

.home > .page-container > .image-container.medium .imgLink > img {
  max-height: 11rem;
}

.home > .page-container > .image-container.small {
  flex-direction: column;
  gap: 1.5rem;
}

.home > .page-container > .image-container.small .imgLink > img {
  max-height: 9rem;
  object-fit: cover;
}

.home .page-container.large {
  width: 80%;
}

.circular-portrait {
  margin: 0 auto;
  position: relative;
  width: 7rem;
  height: 7rem;
  overflow: hidden;
  border-radius: 50%;
}
.circular-portrait img {
  width: 100%;
  height: auto;
}