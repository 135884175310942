.page {
  width: 60%;
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3rem;
}

.page.small {
  width: 96%;
}

.page.medium {
  width: 80%;
}
