.App {
  /* text-align: center; */
  /* background-image: url("./background.jpg");
  background-size: cover; */
  /* background-color: #dcdedc; */
  min-height: 100vh;
}

.App ul {
  list-style: circle;
}

.App a {
  text-decoration: none;
  color: black;
}

.App a.link {
  text-decoration: underline;
  text-decoration-thickness: 2%;
}

.App h1 {
  text-align: center;
}

.App h2 {
  margin-block-start: 2rem;
  margin-block-end: 0.5rem;
}

.App h3 {
  margin-block-start: 1rem;
  margin-block-end: 0.5rem;
}
