.navigation {
  display: flex;
  justify-content: space-around;
  padding: 1rem;
  /* backdrop-filter: blur(16px) saturate(180%); */
  height: 2rem;
  align-items: center;
  border-bottom: 1px solid #000;
}

.navigation .logo {
  height: 2rem;
}

.navigation > .section {
  display: flex;
  align-items: center;
}

.navigation > .section > a {
  margin: 0 1rem;
  padding-bottom: 0.5rem;
}

.navigation > .section a.selected {
  border-bottom: 1px solid black;
  /* padding-bottom: 0.5rem; */
}

.navigation > .section > button.menuButton {
  background-color: transparent;
  border: none;
  font-family: 'Raleway-Light';
  padding-bottom: 0.5rem;
  font-size: 1rem;
  margin: 0 1rem;
  cursor: pointer;
}

.navigation > .section > .menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50px;
  background-color: #d4d9d0;;
  padding: 1rem;
  border: 1px solid black;
  gap: 0.5rem;
  list-style: none;
  margin-block-start: unset;
  margin-left: 1rem;
}



.small > .navigation {
  height: 3.5rem;
}

.small > .navigation .logo {
  height: 3.5rem;
}

.small > .navigation > .menu-button {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.small > .navigation > .start {
  flex: 1;
}

.small > .menu {
  display: flex;
  flex-direction: column;
  background-color: transparent;
  /* border-radius: 0.75rem; */
  border: 1px solid black;
  padding: 0.5rem;
  font-weight: bold;
}

.small > .menu > p.header {
  margin: 0.5rem;

}

.small > .menu > a {
  margin: 0.5rem;
}

.small > .menu > a.submenu {
  margin-left: 1.5rem;
  font-weight: normal;
}

.small > .menu > a.selected {
  background-color: transparent;
  border-radius: 0.75rem;
  border: 1px solid black;
  padding: 0.5rem;
  margin: 0;
}

.small > .menu > a.submenu.selected {
  margin-left: 1.5rem;
}

.small .menu-button > button {
  background: transparent;
  border: none;
  color: black;
}

.small > .menu-button.fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 2s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.small > .menu-button.fade-out {
  opacity: 1;
  animation-name: fadeOutOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 2s;
}

@keyframes fadeOutOpacity {
  100% {
    opacity: 0;
  }
  0% {
    opacity: 1;
  }
}