.image-container {
  display: flex;
  justify-content: center;
}

.image-container .image {
  max-width: 100%;
  object-fit: cover;
}
.image-container .image.large {
  max-width: 75%;
}
.image-container .image.medium {
  max-width: 80%;
}