.info{
  margin: 0;
  font-size: 0.75rem;
}

ul.prices {
  margin-block: 0;
  padding-inline-start: 1.2rem;
}

.bold {
  font-weight: bold;
}

.topMargin {
  margin-top: 2rem;
}