label {
  text-align: left;
  margin-bottom: 0.3rem;
}

textarea {
  font-family: "Open Sans", Calibri, Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  padding: 0.5rem;
  position: relative;  

  border: none;
  background: rgba(255,255,255,0.05);
    backdrop-filter: blur(10px);
    border-top: 1px solid rgba(255,255,255,0.2);
    border-left: 1px solid rgba(255,255,255,0.2);
    box-shadow: 5px 5px 30px rgba(0,0,0,0.2);
  color: #000;
  transition: color 0.3s ease-out;
}